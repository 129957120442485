import React from "react"

function Store (props){

    return(
        <div className={props.darkMode ? "page pageDark" : "page" } >
            <div className={props.darkMode ? "myPage myPageDark" : "myPage" }>         
                <h1 className={props.darkMode ? "h1Dark" : ""} >
                    Store
                </h1>
                <p className={props.darkMode ? "txt txtDark" : "txt" } >
                    Coming Soon...
                </p>
             
            </div>
        </div>
        )}

export default Store