import React from "react"
import Image from "./Image"
function ImageGridRounded(props) {    
    return(
        <div className="imgGridCenter">
            <div className="imgGrid">
                {props.imgURL[0] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[0]}/> : <div></div>}
                {props.imgURL[1] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[1]}/> : <div></div>}
                {props.imgURL[2] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[2]}/> : <div></div>}
                {props.imgURL[3] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[3]}/> : <div></div>}
                {props.imgURL[4] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[4]}/> : <div></div>}
                {props.imgURL[5] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[5]}/> : <div></div>}
                {props.imgURL[6] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[6]}/> : <div></div>}
                {props.imgURL[7] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[7]}/> : <div></div>}
                {props.imgURL[8] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[8]}/> : <div></div>}
                {props.imgURL[9] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[9]}/> : <div></div>}
                {props.imgURL[10] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[10]}/> : <div></div>}
                {props.imgURL[11] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[11]}/> : <div></div>}
                {props.imgURL[12] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[12]}/> : <div></div>}
                {props.imgURL[13] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[13]}/> : <div></div>}
                {props.imgURL[14] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[14]}/> : <div></div>}
                {props.imgURL[15] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[15]}/> : <div></div>}
                {props.imgURL[16] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[16]}/> : <div></div>}
                {props.imgURL[17] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[17]}/> : <div></div>}
                {props.imgURL[18] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[18]}/> : <div></div>}
                {props.imgURL[19] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[19]}/> : <div></div>}
                {props.imgURL[20] ? <Image imgTyp="roundedSquare" imgURL={props.imgURL[20]}/> : <div></div>}                    
            </div>
        </div>
    )}

export default ImageGridRounded