import React from "react"

function Throughline (props){

    return(
        <div className= { props.darkMode ?  "page pageDark" : "page" }>
            <div className= { props.darkMode ? "myPage myPageDark" :  "myPage" }> 
                <h1 className={props.darkMode ? "h1Dark" : ""} >
                    Throughline
                </h1>
                <p className={props.darkMode ? "txt txtDark" : "txt" } >
                    Available now on the iOS App Store
                </p>                               
            </div>
        </div>
        )}

export default Throughline