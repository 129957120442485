import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { CSSTransition, TransitionGroup } from "react-transition-group"
// import KeyboardEventHandler from 'react-keyboard-event-handler'

import '../style.css'
// import Home from "./pageTemplates/Home"
import Art from "./pageTemplates/Art"
import Games from "./pageTemplates/Games"
import Media from "./pageTemplates/Media"
import About from "./pageTemplates/About"
import Navigation from "./pageTemplates/Navigation"
// import Error from "./pageTemplates/Error"
import Store from "./pageTemplates/Store"
import Jewelry from "./pageTemplates/Jewelry"
import Alleycat from "./pageTemplates/Alleycat"
import AlleycatVersionCheck from "./pageTemplates/AlleycatVersionCheck"
import Raccoonspolicy from "./pageTemplates/RaccoonsPolicy"
import CatChaseCheck from "./pageTemplates/CatChaseCheck"
import Throughline from "./pageTemplates/Throughline"
import ThroughlinePolicy from "./pageTemplates/ThroughlinePolicy"
import SliceCameraPolicy from "./pageTemplates/SliceCameraPolicy"
import Vinecraft from "./pageTemplates/Vinecraft"
import VinecraftPolicy from "./pageTemplates/VinecraftPolicy"
import VinecraftVersionCheck from "./pageTemplates/VinecraftVersionCheck"
import AlleycatPolicy from "./pageTemplates/AlleycatPolicy"
// import Survey from "./pageTemplates/Survey.js"
import SliceCamera from './pageTemplates/SliceCamera'
import SecretDoor from './pageTemplates/secretDoor'
import SecretDoorPolicy from './pageTemplates/SecretDoorPolicy'
// import SecretDoorPreview from './pageTemplates/secretDoorPreview'
import FlipTricksPolicy from './pageTemplates/FlipTricksPolicy'
import SecretDoorDownloads from './pageTemplates/secretDoorDownloads';
import DownloadUnavailable from './DownloadUnavailable';

// import darkStyle from '../style_dark.css'

class App extends Component {
  constructor(){
    super()
    this.state = {
        currentPage: "home",
        pageTitle: "Finlay Paterson",
        subtitle: "Artist & Developer",
        darkMode: false
    }
    this.setPage = this.setPage.bind(this)
    this.setPageName = this.setPageName.bind(this)
    this.DetectTimeOfDay = this.DetectTimeOfDay.bind(this)
    this.toggleDarkMode = this.toggleDarkMode.bind(this)
    this.forceDarkMode = this.forceDarkMode.bind(this)
  }

  // pressKey(keyValue){
  //   if (keyValue === "enter" || keyValue === "return"){
  //     this.toggleDarkMode()
  //   }
  // }
  
  setPage(event){     
    this.setState({
      currentPage: event.target.name
    })
  }

  setPageName(pageString){
    console.log("SETTING PAGE " + pageString)
    pageString !== this.state.currentPage && 
    this.setState({
      currentPage: pageString
    })
  }

  DetectTimeOfDay(){
    // set dark mode here
    const date = new Date()
    const hours = date.getHours()
    let dayTime

    if (hours > 6 && hours < 20) {
      dayTime = true
    } else {
      dayTime = false
    }
    if (!dayTime !== this.state.darkMode){
      this.setState({darkMode: !dayTime})
    }
    console.log("Hour is " + hours + ".  Setting Dark CSS Style to " + !dayTime + ".")

    // //if current page is /secretdoor
    // if (location.pathname.indexOf('/secretdoor') !== -1){
    //   this.setState({darkMode: true})
    //   this.render()
    // }
  }

  toggleDarkMode(){
    console.log("TOGGLN' DARK MODE")
    // let isDarkMode = this.state.isDarkMode;
    // this.setDarkMode(!isDarkMode)
    this.setState({darkMode: !this.state.darkMode})
    this.render()
  }


  forceDarkMode(){
    console.log("FORCE DARK MODE")
    // let isDarkMode = this.state.isDarkMode;
    // this.setDarkMode(!isDarkMode)
    this.setState({darkMode: true})
    this.render()
  }

  setDarkMode(isDarkMode){
    // console.log("setting dark mode now...")
    this.setState({darkMode: isDarkMode})
  }
  
  componentDidMount(){
        this.DetectTimeOfDay()    
  }

  // handleKeyPress = (event) => {
  //   if(event.key == 'Enter'){
  //     console.log('enter press here! ')
  //     toggleDarkMode()
  //   }
  // }

  render() {//

//    const KeyboardListener = (props) => (<div>
  //    <KeyboardEventHandler
    //    handleKeys={['enter', 'return']}
      //  let keyValue = ''
        //onKeyEvent={(key, e) => this.pressKey(`${key}`)}
//      /> 

//    </div>);
    // // set dark mode here
    // this.DetectTimeOfDay()
    // console.log("dark mode is " + this.state.darkMode)

    if (this.state.darkMode === true){
    } 
    // require('../style_dark.css');


    //can we set the selected button by getting the URL here?
    // if (this.props.location.pathname === "/"){ this.setPageName("home") }
    // if (this.props.location.pathname === "/art"){ this.setPageName("art")  }
    // if (this.props.location.pathname === "/games"){ this.setPageName("games") }
    // if (this.props.location.pathname === "/media"){ this.setPageName("media") }
    // if (this.props.location.pathname === "/about"){ this.setPageName("about") }
    
    document.body.style.backgroundColor = this.state.darkMode ? "#0F0F0F" : "#EBEBEB"
    
    //override the background colour for specicic pages...
    if (this.props.location.pathname === '/secretdoor'){
      document.body.style.backgroundColor = "#000000"
    }
    if (this.props.location.pathname === '/secret-door'){
      document.body.style.backgroundColor = "#000000"
    }
    if (this.props.location.pathname === '/secretdoordownloads'){
      document.body.style.backgroundColor = "#000000"
    }

    // if (location.pathname.indexOf('/secretdoor') === 1){
    // let location = useLocation();
    // console.log(location);
    // console.log(location.pathname);
    // if (location.pathname === "/about")
    //   document.body.style.backgroundColor = "#0F0F0F"
    // }
  

    return (
      <div >     
         {/* <div> */}
           {/* <KeyboardListener />  */}
           {/* <input type="text" id="one" onKeyPress={this.handleKeyPress} /> */}
        {/* </div> */}

        <BrowserRouter>                           
          <div className= { this.state.darkMode ? "myPageTitleBG myPageTitleBGDark" :"myPageTitleBG"}>      
            <div className= {this.state.darkMode ? "myBody pageDark" : "myBody" }  >
                            
              <Route render={({ location }) => {
                //render the header (called <Navigation>) only if the URL is not /secretdoor
                  return location.pathname.indexOf('/secretdoor') === -1 && location.pathname.indexOf('/secret-door') === -1 && location.pathname.indexOf('/secretdoordownloads') === -1 && location.pathname.indexOf('/downloadUnavailable') === -1 ? 
                  <Navigation 
                  pageTitle={this.state.pageTitle}
                  subTitle={this.state.subtitle}  
                  currentPage={this.state.currentPage}
                  setPage={this.setPage}
                  darkMode={this.state.darkMode}
                  toggleDarkMode={this.toggleDarkMode}
                  /> : null
              }} />

             
              <Route render={({location}) => (
                <div> 
    
                <TransitionGroup >
                  <CSSTransition key={location.key} timeout={900} classNames="fade"> 
                    <Switch location={location}>   
                          {/* <Route exact path="/" render={() => <Home />} />
                          <Route path="/art"  component={Art} />
                          <Route path="/games"  component={Games} />
                          <Route path="/media"  component={Media} />
                          <Route path="/about"  component={About} />   */}                          
                          <Route
                              exact path="/"
                              render={() => (<Art darkMode={this.state.darkMode} /> )}
                          /> 
                          <Route
                            path="/jewelry"
                            render={() => (<Jewelry darkMode={this.state.darkMode}/> )}
                          />         
                          <Route
                            path="/games"
                            render={() => (<Games darkMode={this.state.darkMode} /> )}
                          /> 
                          {/* media url links */}
                          <Route
                            path="/gallery"
                            render={() => ( <Media darkMode={this.state.darkMode} /> )}
                          /> 
                          <Route
                            path="/demo"
                            render={() => ( <Media darkMode={this.state.darkMode} /> )}
                          /> 
                          <Route
                            path="/demoreel"
                            render={() => ( <Media darkMode={this.state.darkMode} /> )}
                          /> 
                          <Route
                            path="/reel"
                            render={() => ( <Media darkMode={this.state.darkMode} /> )}
                          /> 
                          {/* about page url links */}
                           <Route
                            path="/about"
                            render={() => ( <About darkMode={this.state.darkMode}/> )}
                          /> 
                           <Route
                            path="/contact"
                            render={() => ( <About darkMode={this.state.darkMode}/> )}
                          /> 
                           <Route
                            path="/email"
                            render={() => ( <About darkMode={this.state.darkMode}/> )}
                          /> 
                          {/* misc url links */}
                             <Route
                            path="/store"
                            render={() => ( <Store darkMode={this.state.darkMode}/> )}
                          /> 
                                <Route
                            path="/alleycat"
                            render={() => ( <Alleycat darkMode={this.state.darkMode}/> )}
                          /> 
                          <Route
                            path="/alleycatPolicy"
                            render={() => ( <AlleycatPolicy darkMode={this.state.darkMode}/> )}
                          /> 
                                <Route
                            path="/alleycatVersionCheck"
                            render={() => ( <AlleycatVersionCheck darkMode={this.state.darkMode}/> )}
                          /> 
                                <Route
                            path="/raccoonspolicy"
                            render={() => ( <Raccoonspolicy darkMode={this.state.darkMode}/> )}
                          /> 
                                <Route
                            path="/throughline"
                            render={() => ( <Throughline darkMode={this.state.darkMode}/> )}
                          /> 
                                <Route
                            path="/throughlinePolicy"
                            render={() => ( <ThroughlinePolicy darkMode={this.state.darkMode}/> )}
                          /> 
                           <Route
                            path="/sliceCameraPolicy"
                            render={() => ( <SliceCameraPolicy darkMode={this.state.darkMode}/> )}
                          /> 
                                 <Route
                            path="/vinecraft"
                            render={() => ( <Vinecraft darkMode={this.state.darkMode}/> )}
                          /> 
                                 <Route
                            path="/vinecraftPolicy"
                            render={() => ( <VinecraftPolicy darkMode={this.state.darkMode}/> )}
                          /> 
                                 <Route
                            path="/vinecraftVersionCheck"
                            render={() => ( <VinecraftVersionCheck darkMode={this.state.darkMode}/> )}
                          /> 
                                  <Route
                            path="/catChaseCheck"
                            render={() => ( <CatChaseCheck darkMode={this.state.darkMode}/> )}
                          /> 
                          <Route
                            path="/sliceCamera"
                            render={() => ( <SliceCamera darkMode={this.state.darkMode}/> )}
                          /> 
                          <Route
                            path="/slicecamera"
                            render={() => ( <SliceCamera darkMode={this.state.darkMode}/> )}
                          />        
                           <Route
                            path="/secretdoor"
                            render={() => ( <SecretDoor darkMode={this.state.darkMode}/> )}
                          /> 
                           <Route
                            path="/secret-door"
                            // render={() => ( <SecretDoorPreview darkMode={this.state.darkMode}/> )}
                            render={() => ( <SecretDoor darkMode={this.state.darkMode}/> )}
                          /> 
                          <Route
                            path="/secretdoordownloads"
                            // render={() => ( <SecretDoorPreview darkMode={this.state.darkMode}/> )}
                            render={() => ( <SecretDoorDownloads darkMode={this.state.darkMode}/> )}
                          /> 
                          <Route
                            path="/downloadunavailable"
                            // render={() => ( <SecretDoorPreview darkMode={this.state.darkMode}/> )}
                            render={() => ( <DownloadUnavailable darkMode={this.state.darkMode}/> )}
                          /> 
                          <Route
                            path="/SecretDoorPolicy"
                            render={() => ( <SecretDoorPolicy darkMode={this.state.darkMode}/> )}
                          /> 
                                <Route
                            path="/FlipTricksPolicy"
                            render={() => ( <FlipTricksPolicy darkMode={this.state.darkMode}/> )}
                          /> 




                        {/* 
                          <Route
                            path="/critiquetool"
                            render={() => ( <Survey darkMode={this.state.darkMode}/> )}
                          /> 
                                   <Route
                            path="/crittool"
                            render={() => ( <Survey darkMode={this.state.darkMode}/> )}
                          /> 

                          <Route
                            path="/crit"
                            render={() => ( <Survey darkMode={this.state.darkMode}/> )}
                          />  
                        */}


                          {/* catch bad urls here */}
                          <Route
                            render={() => ( <About darkMode={this.state.darkMode}/> )}
                            // render={() => ( <Error darkMode={this.state.darkMode}/> )}
                            /> 
                      </Switch>
                  </CSSTransition>
              </TransitionGroup> 
              </div>
              )} />
            </div>
          </div>
        </BrowserRouter>
      </div>
    )
  }
}
export default App;