import React from "react"
import NavigationButton from "../NavigationButton"
import  { NavLink } from "react-router-dom"

class Navigation extends React.Component {    
    render() {//    
        const siteName = this.props.pageTitle.toUpperCase()
        const subTitle = this.props.subTitle.toUpperCase()
        // const nameLogo = window.location.origin + "/img/finlay.svg"

        const sunIcon = window.location.origin + "/img/sun.svg"
        const moonIcon = window.location.origin + "/img/moon.svg"

        // will produce a string starting with a / like "/about"
        let urlName = window.location.pathname
        if (urlName === "/art") urlName = "/?"
        if (urlName === "/") urlName = "/art"
        // console.log("PATH IS " + urlName) 

        return(  
            <div>                
                <div className="myHeader"> 
                    
                    
                    <div className="myPageTitle" onClick={this.props.toggleDarkMode}>
                        {/* <img src={nameLogo} className="logoImg" alt="" onClick={this.props.toggleDarkMode} /> */}



                        <h2 className={ this.props.darkMode ? "titleText titleTextDark" : "titleText" }>
                            {siteName}
                        </h2>
                        <h4 className={ this.props.darkMode ? "subtitleText subtitleTextDark" :"subtitleText"}>
                            {subTitle}
                        </h4>
                    </div>     
                    <br/>
                    <div className="buttonBarCenter">
                        <div className="buttonBar" >       
                            { urlName === "/art" ? (<NavigationButton darkMode={this.props.darkMode} name="art"/>) : (<div> <NavLink to="/"> <NavigationButton name="art" seletedButton={this.props.currentPage} darkMode={this.props.darkMode} /> </NavLink></div>) }
                            { urlName === "/jewelry" ? (<NavigationButton darkMode={this.props.darkMode} name="jewelry"/>) : (<div> <NavLink to="/jewelry"> <NavigationButton name="jewelry" seletedButton={this.props.currentPage} darkMode={this.props.darkMode} /> </NavLink></div>) }
                            { urlName === "/games" ? (<NavigationButton darkMode={this.props.darkMode} name="games"/>) : (<div> <NavLink to="/games"> <NavigationButton name="games" seletedButton={this.props.currentPage} darkMode={this.props.darkMode} /> </NavLink></div>) }
                            { urlName === "/gallery" ? (<NavigationButton darkMode={this.props.darkMode} name="gallery"/>) : (<div> <NavLink to="/gallery"> <NavigationButton name="gallery" seletedButton={this.props.currentPage} darkMode={this.props.darkMode} /> </NavLink></div>) }
                            { urlName === "/about" ? (<NavigationButton darkMode={this.props.darkMode} name="about"/>) : (<div> <NavLink to="/about"> <NavigationButton name="about" seletedButton={this.props.currentPage} darkMode={this.props.darkMode} /> </NavLink></div>) }
                        </div> 
                    </div>
                </div>
                {this.props.darkMode ? 
                    <img src={sunIcon} className="icon" alt="" onClick={this.props.toggleDarkMode} /> : 
                    <img src={moonIcon} className="icon" alt="" onClick={this.props.toggleDarkMode} />
                }
            </div>
        )}
    }
export default Navigation