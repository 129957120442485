import React from "react"
import PrivacyPolicy from "./PrivacyPolicy"

function VinecraftPolicy (props){

    return(
        <div>
        <br/>
            <PrivacyPolicy darkMode={props.darkMode} policyStartDate={"January 31, 2019"} gameTitle="Vinecraft" />
        </div>
       
        )}

export default VinecraftPolicy