import React from "react"
import ImagePopup from "./ImagePopup"

class Image extends React.Component {
    constructor(){
        super()
        this.state = {
            showModal: false
        }  
        this.closePopup = this.closePopup.bind(this)
      }

      closePopup(){
        // console.log("CLOSE POPUP")
        this.setState({showModal: false})
      }

      render(){        
        const imgPath = window.location.origin + this.props.imgURL
        const imgType = this.props.imgTyp
        // console.log("IMG TYPE IS " + imgType + " | " + imgPath)

        return(
          
                <div className="inlineBlock">     
                    {imgType === "square" ? <img className="img" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/ > : "" }  
                    {imgType === null ? <img className="img" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }    
                    {imgType === "bigPortrait" ? <img className="bigPortraitImg" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "portrait" ? <img className="portraitImg" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }  
                    {imgType === "landscape" ? <img className="landscapeImg" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "landscapeB" ? <img className="landscapeImgB" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "landscapeC" ? <img className="landscapeImgC" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "bigLandscape" ? <img className="bigLandscapeImg" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "singleImage" ? <img className="singleImage" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "bigPortraitImgB" ? <img className="bigPortraitImgB" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {imgType === "roundedSquare" ? <img className="roundedSquare" onClick={ () => this.setState({showModal: true}) } src={imgPath} alt=""/> : "" }   
                    {this.state.showModal ? (<ImagePopup closePopup={this.closePopup} imgPath={imgPath}/>) : ""  }                  

                </div>        
    )}
}

export default Image