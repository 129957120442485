import React from "react"
import Image from "./Image"
function GameImagePortrait(props) {
//    const imgPath = window.location.origin + props.imgURL
    return(
        <div className="imgGridCenter">
                <div className="gameTitleBar">
                    
                        { props.trailerURL === "" && props.downloadURL === "" ?
                            // no links available, use full width
                            (<div className={props.darkMode ? "titleBoxFull titleBoxFullDark" : "titleBoxFull" }> <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>) : 
                            (<div/>)
                        }
                       
                                            
                        { props.trailerURL !== "" && props.downloadURL === "" ?
                            // only trailer url available
                        (<div>
                                <div className= {props.darkMode ? "titleBoxWide titleBoxWideDark" : "titleBoxWide" } > <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>
                                <a href={props.trailerURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Trailer</div></div></a>
                            </div>)
                        : (<div/>)
                        }
                        { props.trailerURL === "" && props.downloadURL !== "" ?
                            // only download URL available
                            (<div>
                                <div className= {props.darkMode ? "titleBoxWide titleBoxWideDark" : "titleBoxWide" } > <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>
                                <a href={props.downloadURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Download</div></div></a>
                            </div>)
                            : (<div/>)
                        }
                        { props.trailerURL !== "" && props.downloadURL !== "" ?
                            // both download URL and trailer URL available
                            (<div>
                                <div className={props.darkMode ? "titleBox titleBoxDark" : "titleBox" } > <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>
                                <a href={props.trailerURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Trailer</div></div></a>
                                <a href={props.downloadURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Download</div></div></a>
                            </div>)
                            : (<div/>)
                        }
                </div>

                <div className="gameInfoContainerPortrait">                   
                    <div className="gamePosterImg"><Image imgURL={props.posterImg} imgTyp="bigPortrait"/></div>                               
                    <div className="gameImg"><Image imgURL={props.preview0} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview1} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview2} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview3} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview4} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview5} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview6} imgTyp="portrait"/></div>
                    <div className="gameImg"><Image imgURL={props.preview7} imgTyp="portrait"/></div>                  
                </div>
            <div className="umm">

                <div className={props.darkMode ? "descriptionBoxB descriptionBoxDark" : "descriptionBoxB" }>
                    <div className={props.darkMode ? "descriptionBoxText descriptionBoxTextDark" : "descriptionBoxText" }>
                        {props.descriptionText}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameImagePortrait



