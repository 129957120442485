import React from "react"
import PrivacyPolicy from "./PrivacyPolicy"

function RaccoonsPolicy (props){

    return(
        <div>
        <br/>
            <PrivacyPolicy darkMode={props.darkMode} policyStartDate={"January 31, 2019"} gameTitle="Toronto Raccoons" usesGPS={true} />
        </div>
       
        )}

export default RaccoonsPolicy