import React from "react"
import PrivacyPolicy from "./PrivacyPolicy"

function SliceCameraPolicy (props){

    return(
        <div>
        <br/>
            <PrivacyPolicy darkMode={props.darkMode} policyStartDate={"September 1, 2019"} gameTitle="Slice Camera" />
        </div>
       
        )}

export default SliceCameraPolicy