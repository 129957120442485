import React from "react"
import PrivacyPolicy from "./PrivacyPolicy"

function FlipTricksPolicy (props){

    return(
        <div>
        <br/>
            <PrivacyPolicy darkMode={props.darkMode} policyStartDate={"October 1, 2020"} gameTitle="Flip Tricks" />
        </div>
       
        )}

export default FlipTricksPolicy