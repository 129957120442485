import React from "react"

function IconButton(props) {
    const imgPath = window.location.origin + props.imgURL
//    console.log("path is: " + imgPath)
    
    return(
        <div className={props.darkMode ? "iconButton iconButtonDark" : "iconButton" }> 
            <img className={props.darkMode?"iconImage iconImageDark" : "iconImage"} src={imgPath} alt="   "/> 
         </div>
    )
}

export default IconButton