import React from "react"
import ImageGrid from "../ImageGrid"
import ImageGridRounded from "../ImageGridRounded.js"
const videoImg = "/img/videoPreview.jpg"


//group zero LOW POLYGON MODELS
const media0Img1 = "/img/media0_01.jpg"
// const media0Img2 = "/img/media0_02.jpg"
const media0Img3 = "/img/media0_03.jpg"
const media0Img4 = "/img/media0_04.jpg"
const media0Img5 = "/img/media0_05.jpg"
// const media0Img6 = "/img/media0_06.jpg"
const media0Img7 = "/img/media0_07.jpg"
// const media0Img8 = "/img/media0_08.jpg"
// const media0Img9 = "/img/media0_09.jpg"
// const media0Img10 = "/img/media0_10.jpg"
const media0Img11 = "/img/media0_11.jpg"
// const media0Img12 = "/img/media0_12.jpg"
const media0Img13 = "/img/media0_13.jpg"
// const media0Img14 = "/img/media0_14.jpg"
// const media0Img15 = "/img/media0_15.jpg"
const media0Img16 = "/img/media0_16.jpg"
const media0Img17 = "/img/media0_17.jpg"
const media0Img18 = "/img/media0_18.jpg"
const media0Img19 = "/img/media0_19.jpg"
const media0Img20 = "/img/media0_20.jpg"
const media0Img21 = "/img/media0_21.jpg"
const media0Img22 = "/img/media0_22.jpg"
const media0Img23 = "/img/media0_23.jpg"
const media0Img24 = "/img/media0_24.jpg"
const media0Img25 = "/img/media0_25.jpg"
const media0Img26 = "/img/media0_26.jpg"
const media0Img27 = "/img/media0_27.jpg"
const media0Img28 = "/img/media0_28.jpg"
const media0Img29 = "/img/media0_29.jpg"
const media0Img30 = "/img/media0_30.jpg"
const media0Img31 = "/img/media0_31.jpg"
const media0Img32 = "/img/media0_32.jpg"
const media0Img33 = "/img/media0_33.jpg"
// const media0Img34 = "/img/media0_34.jpg"
const media0Img35 = "/img/media0_35.jpg"
const media0Img36 = "/img/media0_36.jpg"



//group one CHARACTER DESIGN
const media1Img1 = "/img/media1_01.jpg"
const media1Img2 = "/img/media1_02.jpg"
const media1Img3 = "/img/media1_03.jpg"
const media1Img4 = "/img/media1_04.jpg"
const media1Img5 = "/img/media1_05.jpg"
const media1Img6 = "/img/media1_06.jpg"
const media1Img7 = "/img/media1_07.jpg"
const media1Img8 = "/img/media1_08.jpg"
const media1Img9 = "/img/media1_09.jpg"
const media1Img10 = "/img/media1_10.jpg"
const media1Img11 = "/img/media1_11.jpg"
// const media1Img12 = "/img/media1_12.jpg"
const media1Img13 = "/img/media1_13.jpg"
const media1Img14 = "/img/media1_14.jpg"
// const media1Img15 = "/img/media1_15.jpg"
// const media1Img16 = "/img/media1_16.jpg"
const media1Img17 = "/img/media1_17.jpg"
const media1Img18 = "/img/media1_18.jpg"
// const media1Img19 = "/img/media1_19.jpg"
// const media1Img20 = "/img/media1_20.jpg"

const media1Img21 = "/img/media1_21.jpg"
const media1Img22 = "/img/media1_22.jpg"
const media1Img23 = "/img/media1_23.jpg"
const media1Img24 = "/img/media1_24.jpg"
const media1Img25 = "/img/media1_25.jpg"
// const media1Img26 = "/img/media1_26.jpg"




//group two ENRIRONMENTS
const media2Img1 = "/img/media2_01.jpg"
const media2Img2 = "/img/media2_02.jpg"
// const media2Img3 = "/img/media2_03.jpg"
const media2Img4 = "/img/media2_04.jpg"
const media2Img5 = "/img/media2_05.jpg"
const media2Img6 = "/img/media2_06.jpg"
// const media2Img7 = "/img/media2_07.jpg"
// const media2Img8 = "/img/media2_08.jpg"
// const media2Img9 = "/img/media2_09.jpg"
const media2Img10 = "/img/media2_10.jpg"
const media2Img11 = "/img/media2_11.jpg"
const media2Img12 = "/img/media2_12.jpg"
const media2Img13 = "/img/media2_13.jpg"
const media2Img14 = "/img/media2_14.jpg"
const media2Img15 = "/img/media2_15.jpg"
// const media2Img16 = "/img/media2_16.jpg"
const media2Img17 = "/img/media2_17.jpg"
// const media2Img18 = "/img/media2_18.jpg"
// const media2Img19 = "/img/media2_19.jpg"
const media2Img20 = "/img/media2_20.jpg"
const media2Img21 = "/img/media2_21.jpg"
const media2Img22 = "/img/media2_22.jpg"
const media2Img23 = "/img/media2_23.jpg"
const media2Img24 = "/img/media2_24.jpg"
const media2Img25 = "/img/media2_25.jpg"
const media2Img26 = "/img/media2_26.jpg"
const media2Img27 = "/img/media2_27.jpg"
// const media2Img28 = "/img/media2_28.jpg"


//group three 3D RENDERS
const media3Img1 = "/img/media3_01.jpg"
const media3Img2 = "/img/media3_02.jpg"
const media3Img3 = "/img/media3_03.jpg"
const media3Img4 = "/img/media3_04.jpg"
const media3Img5 = "/img/media3_05.jpg"
const media3Img6 = "/img/media3_06.jpg"
const media3Img7 = "/img/media3_07.jpg"
const media3Img8 = "/img/media3_08.jpg"
// const media3Img9 = "/img/media3_09.jpg"
const media3Img10 = "/img/media3_10.jpg"
const media3Img11 = "/img/media3_11.jpg"
// const media3Img12 = "/img/media3_12.jpg"
const media3Img13 = "/img/media3_13.jpg"
const media3Img14 = "/img/media3_14.jpg"
const media3Img15 = "/img/media3_15.jpg"


//group four 3D SCANNING
// const media4Img1 = "/img/media4_01.jpg"
// const media4Img2 = "/img/media4_02.jpg"
// const media4Img3 = "/img/media4_03.jpg"
// const media4Img4 = "/img/media4_04.jpg"


//group five APP ICONS
const media5Img1 = "/img/media5_01.jpg"
const media5Img2 = "/img/media5_02.jpg"
const media5Img3 = "/img/media5_03.jpg"
const media5Img4 = "/img/media5_04.jpg"
const media5Img5 = "/img/media5_05.jpg"
const media5Img6 = "/img/media5_06.jpg"
const media5Img7 = "/img/media5_07.jpg"
const media5Img8 = "/img/media5_08.jpg"
const media5Img9 = "/img/media5_09.jpg"
const media5Img10 = "/img/media5_10.jpg"
// const media5Img11 = "/img/media5_11.jpg"
const media5Img12 = "/img/media5_12.jpg"
const media5Img13 = "/img/media5_13.jpg"



class Media extends React.Component {
    constructor(){
        super()
        this.state = {
            enableVideo: false,           
        }
      }

    render() {    
        return(
            <div className={ this.props.darkMode ?  "page pageDark" : "page" }>
                <div>
                    <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }> 
                        <h1 className={this.props.darkMode ? "h1Dark" : ""}> Demo Reel</h1>                                   
                        <div className ="imgGridCenter"> 
                            {!this.state.enableVideo ? 
                            <img 
                                className="videoPreviewImg"
                                src={videoImg} 
                                alt=""
                                onClick={ () => this.setState({enableVideo: true}) }
                            /> 
                            :         
                            <div className="sixteenNineAspect">                               
                                <iframe 
                                    className = "movie"
                                    title="YoutubeVideo"
                                    id="0912"                                
                                    src="https://www.youtube.com/embed/IrQ9vdu5tDQ?playlist=IrQ9vdu5tDQ&hd=1&rel=0&autoplay=1&loop=1&playsinline=1" 
                                    frameBorder="0" 
                                    allow="autoplay; encrypted-media;" 
                                    allowFullScreen
                                    >
                                </iframe> 
                            </div>
                            }
                        </div>
                    <br/>
                    </div>   
                    <br/>
                    <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }> 
                        <h1 className={this.props.darkMode ? "indent h1Dark" : "indent"}>Low Polygon Models</h1>
                        {/* photo gallery here */}
                        <ImageGrid imgURL={[
                            media0Img16,
                            media0Img17,
                            media0Img18,
                            media0Img19,
                            media0Img20,
                            media0Img21,
                            media0Img28,
                            media0Img29,
                            media0Img30,
                            media0Img31,
                            media0Img22,
                            media0Img23,
                            media0Img24,
                            media0Img25,
                            media0Img26,
                            media0Img27,
                            media0Img33,
                            media0Img32,
                            // media0Img34,
                            media0Img35,
                            media0Img36,                            
                        ]}/>
                            <ImageGrid imgURL={[                           
                            // media0Img2,
                            // media0Img8,
                            media0Img3,
                            media0Img11,
                            media0Img5,
                            media0Img1,
                            // media0Img6,
                            media0Img13,
                            media0Img4,
                            media0Img7,
                            media3Img2,

                            // media0Img12,
                            // media0Img10,
                            // media0Img9,
                            // media0Img14,
                            // media0Img15
                            ]}/>
                                           <br/>
                    </div>
                    <br/>
                    <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }> 
                        <h1 className={this.props.darkMode ? "indent h1Dark" : "indent"}>Character Designs</h1>
                        {/* photo gallery here */}
                        <ImageGrid imgURL={[
                            media1Img23,
                            media1Img2,
                            media1Img22,
                            media1Img1,
                            media1Img24,
                            media1Img4,
                            media1Img25,                                       
                            // media1Img26,                                       
                            media1Img6,
                            media1Img3,
                            media1Img21,
                            media1Img5,
                            media1Img7,
                            media1Img11,
                            media1Img8,
                            media1Img9,
                            media1Img10,
                            media1Img14,
                            media1Img13,
                            media1Img18,
                            media1Img17,
                            // media1Img12,
                            // media1Img19,
                        ]}/>
                         <ImageGrid imgURL={[
                            // media1Img15,
                            // media1Img16,
                            // media1Img20,
                        ]}/>
                                           <br/>
                    </div>
                    <br/>
                    <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }> 
                        <h1 className={this.props.darkMode ? "indent h1Dark" : "indent"}>Environments</h1>
                        {/* photo gallery here */}
                        <ImageGrid imgURL={[
                            media2Img20,
                            media2Img27,
                            media2Img22,
                            media2Img23,
                            media2Img24,
                            media2Img25,
                            media2Img21,
                            media2Img26,
                            media2Img2,
                            media2Img1,
                            media2Img5,
                            media2Img4,
                            media2Img6,
                            media2Img10,
                            media2Img12,
                            media2Img11,
                        ]}/>
                        <ImageGrid imgURL={[
                            media2Img14,
                            media2Img13,
                            media2Img17,
                            media2Img15 ]}/>
                        </div>
                        <br/>
                        <br/>
                        <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }> 
                        <h1 className={this.props.darkMode ? "indent h1Dark" : "indent"}>3D Rendering</h1>
                        {/* photo gallery here */}
                            <ImageGrid imgURL={[media3Img1,
                                                // media3Img2,
                                                media3Img3,
                                                media3Img4,
                                                media3Img5,
                                                media3Img6,
                                                media3Img7,
                                                media3Img8,
                                                // media3Img9,
                                                media3Img10,
                                                media3Img11,
                                                // media3Img12,
                                                media3Img13,
                                                media3Img14,
                                                media3Img15,
                                                                        
                                            ]}/>
                                    <br/>
                        </div>
                        <br/>
                        {/* photo gallery here */}
                        {/* <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }>  */}
                        {/* <h1 className={this.props.darkMode ? "indent h1Dark" : "indent"}>3D Scanning</h1>
                        <ImageGrid imgURL={[media4Img1,
                                            media4Img2,
                                            media4Img3,
                                            media4Img4,
                                                                    
                                            ]}/>
                        </div>
                        <br/> */}
                        <div className={ this.props.darkMode ?  "myMediaPage myMediaPageDark" : "myMediaPage" }> 
                        <h1 className={this.props.darkMode ? "indent h1Dark" : "indent"}>App Icons</h1>
                        {/* photo gallery here */}
                        <ImageGridRounded
                                // roundedCorners={1};    
                                imgURL={[
                                        media5Img13,
                                        media5Img5,
                                        media5Img2,
                                        media5Img10,
                                        media5Img9,
                                        media5Img4,
                                        media5Img1,
                                        media5Img8,
                                        media5Img3,
                                        media5Img6,
                                        media5Img12,
                                        media5Img7,
                                        ]}/>
                        <br/>
                    </div>
                </div>
            </div>
            )}
        }

export default Media