import React from "react"
import Image from "./Image"
function GameImageLandscapeB(props) {
//    const imgPath = window.location.origin + props.imgURL


    return(
        <div className="imgGridCenter">
                <div className="gameTitleBar">
                    
                        { props.trailerURL === "" && props.downloadURL === "" ?
                            // no links available, use full width
                            (<div className={props.darkMode ? "titleBoxFull titleBoxFullDark" : "titleBoxFull" }> <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>) : 
                            (<div/>)
                        }
                       
                                            
                        { props.trailerURL !== "" && props.downloadURL === "" ?
                            // only trailer url available
                        (<div>
                                <div className= {props.darkMode ? "titleBoxWide titleBoxWideDark" : "titleBoxWide" } > <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>
                                <a href={props.trailerURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Trailer</div></div></a>
                            </div>)
                        : (<div/>)
                        }
                        { props.trailerURL === "" && props.downloadURL !== "" ?
                            // only download URL available
                            (<div>
                                <div className= {props.darkMode ? "titleBoxWide titleBoxWideDark" : "titleBoxWide" } > <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>
                                <a href={props.downloadURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Download</div></div></a>
                            </div>)
                            : (<div/>)
                        }
                        { props.trailerURL !== "" && props.downloadURL !== "" ?
                            // both download URL and trailer URL available
                            (<div>
                                <div className={props.darkMode ? "titleBox titleBoxDark" : "titleBox" } > <div className={props.darkMode ? "gameTitle gameTitleDark" : "gameTitle" } > {props.gameTitle.toUpperCase()} </div> </div>
                                <a href={props.trailerURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Trailer</div></div></a>
                                
                                { props.gameTitle === "Secret Door" ? 
                                <a href={props.downloadURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Beta</div></div></a>
                                :
                                <a href={props.downloadURL}><div className={props.darkMode ? "titleBoxLink titleBoxLinkDark" : "titleBoxLink" }><div className={props.darkMode ? "gameLink gameLinkDark" : "gameLink" }>Download</div></div></a>
                                 }
                            </div>)
                            : (<div/>)
                        }
                </div>

                <div className="gameInfoContainerLandscape">                   
                    <div className="gamePosterImgB"><Image imgURL={props.posterImg} imgTyp="bigPortraitImgB"/></div>                               
                    <div className="gameImg"><Image imgURL={props.preview0} imgTyp="landscape"/></div>
                    <div className="gameImg"><Image imgURL={props.preview1} imgTyp="landscape"/></div>
                    <div className="gameImg"><Image imgURL={props.preview2} imgTyp="landscape"/></div>
                    <div className="gameImg"><Image imgURL={props.preview3} imgTyp="landscape"/></div>
                    <div className="gameImg"><Image imgURL={props.preview4} imgTyp="landscape"/></div>
                    <div className="gameImg"><Image imgURL={props.preview5} imgTyp="landscape"/></div>
                </div>
            <div className="umm">

                { props.gameTitle === "Secret Door" ?
                    
                    <div className={props.darkMode ? "descriptionBox descriptionBoxDark" : "descriptionBox" }>
                        <div className={props.darkMode ? "descriptionBoxText descriptionBoxTextDark" : "descriptionBoxText" }>
                          {/* download link in description */}
                            <a href={props.downloadURL}>
                            <div className={props.darkMode ? "descriptionBoxText descriptionBoxTextDark descriptionBoxLink" : "descriptionBoxText descriptionBoxLink" }> 
                            Beta Test Page
                            </div>
                            </a>                        
                            {props.descriptionText}
                            <br/>
                            <br/>
                            {"This non-violent game is focused on player choice and decision making, with a world full of characters to talk to and unique places to explore."}
                            <br/>
                            <br/>
                            {"Secret Door supports touchscreen and game controller inputs, as well as mouse and keyboard on supported platforms. It currently runs on iOS, Android, Mac, PC, and Linux."}
                        </div>
                    </div>
                    : 
                    <div className={props.darkMode ? "descriptionBox descriptionBoxDark" : "descriptionBox" }>
                        <div className={props.darkMode ? "descriptionBoxText descriptionBoxTextDark" : "descriptionBoxText" }>
                            {props.descriptionText}
                        </div>
                    </div>
                }

                
            </div>
        </div>
    )
}

export default GameImageLandscapeB



