import React from "react"
import ImagePopup from "./ImagePopup"


class SingleImage extends React.Component {

    constructor(){
        super()
        this.state = {
            showModal: "0"
        }  
        this.closePopup = this.closePopup.bind(this)
      }

      closePopup(){
        console.log("CLOSE POPUP - state before:" + this.state.showModal)
        this.setState({showModal: "0"})
      }


    render(){
        const imgPath = window.location.origin + this.props.imgURL        
        return(
            <div className="singleGrid">  
                <img className="singleImage" onClick={ () => this.setState({showModal: "1"}) } src={imgPath} alt=""/>
                {this.state.showModal !== "0" ? (<ImagePopup closePopup={this.closePopup} imgPath={imgPath}/>) : ""  }                           
            </div>
    )}
}
export default SingleImage