import React from "react"

class NavigationButton extends React.Component{   
    render(){
        let urlName = window.location.pathname

        if (urlName === "/art") urlName = "/?"
        if (urlName === "/") urlName = "/art"
        const pageName = "/" + this.props.name 
        const txt = this.props.name
        // console.log("URL NAME " + urlName + " | " + pageName)
        return(
            <div>  
            {/* this statement conditionally renders the button */}
            {/* with different CSS if its the selected button   */}
                {urlName !== pageName ?
                    <div                     
                        className={ this.props.darkMode ? "navButton navButtonDark" :  "navButton" }  
                        name={this.props.name}
                    > {txt.toUpperCase()} </div>                
                :
                    <div                     
                        className={ this.props.darkMode ? "selectedNavButton selectedNavButtonDark" :  "selectedNavButton" } 
                        name={this.props.name}
                    > {txt.toUpperCase()} </div>                
                }
            </div>
        )
    }
}

export default NavigationButton