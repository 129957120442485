import React from "react"
import GameWebpage from "../GameWebpage"

function SecretDoor (props){

    return(
                <div className="secretDoorPageStyle">
                    <GameWebpage/>
                </div>           
        )}

export default SecretDoor