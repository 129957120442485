import React from "react"

function PrivacyPolicy (props){
    const policyText = "Though this app uses your GPS location, it is not saved locally " + 
                        "or sent to any servers. However since the game is limited to Toronto, in-game " + 
                        "achievements will reveal some data about where you have been."



    return(
        <div className= { props.darkMode ?  "page pageDark" : "page" }>
            <div className= { props.darkMode ? "myPage myPageDark" :  "myPage" }> 
                <br/>
                <h1 className={props.darkMode ? "h1Dark" : ""} >
                {props.gameTitle} Privacy Policy
                </h1>
                <div className={props.darkMode ? "txt txtDark" : "txt" }>                    

                    {/* show / hide GPS text at the beginning */}
                    {props.usesGPS ? <p> {policyText} </p> : <p> </p> }

                    <p>
                        {/* Effective date: January 31, 2019 */}
                        Effective date: { props.policyStartDate }
                    </p>   
                    <p>
                        This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.
                    </p>   
                    <p>
                        We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. 
                    </p>  
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Information Collection And Use </h1> 
                    <p>
                        We collect several different types of information for various purposes to provide and improve our Service to you.
                    </p>   
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Types of Data Collected</h1>  
                    <p> Personal Data </p>
                    <p>
                        While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
                    </p>        
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Usage Data </h1>   
                    <p>
                        When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data (“Usage Data”).
                    </p>    
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Use of Data </h1>   
                    <p> {props.gameTitle} uses the collected data for various purposes: </p>
                    <p>• To provide and maintain the Service</p>
                    <p>• To notify you about changes to our Service</p>
                    <p>• To allow you to participate in interactive features of our Service when you choose to do so</p>
                    <p>• To provide customer care and support</p>
                    <p>• To provide analysis or valuable information so that we can improve the Service</p>
                    <p>• To monitor the usage of the Service</p>
                    <p>• To detect, prevent and address technical issues</p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Transfer Of Data </h1>   
                    <p>
                        Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.
                    </p>
                    <p>
                        If you are located outside Canada and choose to provide information to us, please note that we transfer the data, including Personal Data, to Canada and process it there.
                    </p>
                    <p>
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                    </p>
                    <p>
                    {props.gameTitle} will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                    </p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Disclosure Of Data </h1>
                    <p> Legal Requirements </p>
                    <p>
                    {props.gameTitle} may disclose your Personal Data in the good faith belief that such action is necessary to:
                    </p>
                    <p>• To comply with a legal obligation</p>
                    <p>• To protect and defend the rights or property of {props.gameTitle}</p>
                    <p>• To prevent or investigate possible wrongdoing in connection with the Service</p>
                    <p>• To protect the personal safety of users of the Service or the public</p>
                    <p>• To protect against legal liability</p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Security Of Data </h1>
                    <p>
                        The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                    </p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Service Providers </h1>
                    <p>
                        We may employ third party companies and individuals to facilitate our Service (“Service Providers”), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.
                    </p>
                    <p>
                        These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }> Analytics </h1>
                    <p>
                        We may use third-party Service Providers to monitor and analyze the use of our Service.
                    </p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }>Links To Other Sites</h1>
                    <p>
                        Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit.
                    </p>
                    <p>
                        We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
                    </p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }>
                        Changes To This Privacy Policy
                    </h1>
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>
                    <p>
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
                    </p>
                    <h1 className={props.darkMode ? "txt txtDark" : "txt" }>Contact Us</h1>
                    <p>If you have any questions about this Privacy Policy, please contact us:</p>
                    <p> By email: finlaypatersongames (at) gmail (dot) com </p>
                </div>
            </div>
        </div>
        )}

export default PrivacyPolicy