import React from "react"
import PrivacyPolicy from "./PrivacyPolicy"

function SecretDoorPolicy (props){

    return(
        <div>
        <br/>
            <PrivacyPolicy darkMode={props.darkMode} policyStartDate={"November 1, 2019"} gameTitle="Secret Door" />
        </div>
       
        )}

export default SecretDoorPolicy