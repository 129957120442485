import React from "react"

function VinecraftVersionCheck (props){

    return(
        <div className= { props.darkMode ?  "page pageDark" : "page" }>
            <div className= { props.darkMode ? "myPage myPageDark" :  "myPage" }> 
                <p className={props.darkMode ? "txt txtDark" : "txt" } >
                ~1.01~                            
                </p>  
            </div>
        </div>
        )}

export default VinecraftVersionCheck