import React from "react"
import ImagePopup from "./ImagePopup"

class DoubleImage extends React.Component {
    constructor(){
        super()
        this.state = {
            showModal: "0"
        }  
        this.closePopup = this.closePopup.bind(this)
      }

      closePopup(){
        console.log("CLOSE POPUP - state before:" + this.state.showModal)
        this.setState({showModal: "0"})
      }

    render(){
        const imgPathA = window.location.origin + this.props.imgURLA
        const imgPathB = window.location.origin + this.props.imgURLB
        let selectedImgPath = imgPathA
        if (this.state.showModal === "2"){ selectedImgPath = imgPathB }

        return(
            <div className="doubleGrid">  
                <img className="doubleImage" onClick={ () => this.setState({showModal: "1"}, console.log("CLICKED " + this.imgPathA)) } src={imgPathA} alt=""/>
                <img className="doubleImage" onClick={ () => this.setState({showModal: "2"}) } src={imgPathB} alt=""/>
                {this.state.showModal !== "0" ? (<ImagePopup closePopup={this.closePopup} imgPath={selectedImgPath}/>) : ""  }                        
            </div>
    )}
}
export default DoubleImage