import React from "react"
import ImagePopup from "./ImagePopup"


class QuadImage extends React.Component {
    constructor(){
        super()
        this.state = {
            showModal: "0"
        }  
        this.closePopup = this.closePopup.bind(this)
      }

      closePopup(){
        console.log("CLOSE POPUP - state before:" + this.state.showModal)
        this.setState({showModal: "0"})
      }


    render(){
        const imgPathA= window.location.origin + this.props.imgURLA
        const imgPathB = window.location.origin + this.props.imgURLB
        const imgPathC = window.location.origin + this.props.imgURLC
        const imgPathD = window.location.origin + this.props.imgURLD
        let selectedImgPath = imgPathA
        if (this.state.showModal === "2"){ selectedImgPath = imgPathB }
        if (this.state.showModal === "3"){ selectedImgPath = imgPathC }
        if (this.state.showModal === "4"){ selectedImgPath = imgPathD }
        
        return(
            <div className="quadGrid">    
                <img className="quadImage" onClick={ () => this.setState({showModal: "1"}) } src={imgPathA} alt=""/>
                <img className="quadImage" onClick={ () => this.setState({showModal: "2"}) } src={imgPathB} alt=""/>
                <img className="quadImage" onClick={ () => this.setState({showModal: "3"}) } src={imgPathC} alt=""/>
                <img className="quadImage" onClick={ () => this.setState({showModal: "4"}) } src={imgPathD} alt=""/>
                {this.state.showModal !== "0" ? (<ImagePopup closePopup={this.closePopup} imgPath={selectedImgPath}/>) : ""  }                         
            </div>
    )}
}
export default QuadImage