import React from "react"

class ImagePopup extends React.Component {
    render(){        
       
        return(
            <div className="popupModal">     
                <img className="modalImg" onClick={ this.props.closePopup } src={this.props.imgPath} alt=""/>
                
            </div>
    )}
}

export default ImagePopup