import React from "react"
import DownloadPage from "../SecretDoorDownloadPage"

function SecretDoorDownloads (props){

    return(
                <div className="secretDoorPageStyle">
                    <DownloadPage/>
                </div>           
        )}

export default SecretDoorDownloads