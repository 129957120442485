import React from "react"
import IconButton from "./IconButton"

class LabeledIconButton extends React.Component {   
    render(){        
        return(
            <div className="socialCenter">
                <a href={this.props.linkURL}>
                    <div className={this.props.darkMode ? "labeledIconButton labeledIconButtonDark" : "labeledIconButton" }>

                            <IconButton darkMode={this.props.darkMode} imgURL={this.props.imgURL}/>  
                            <div className={this.props.darkMode ? "labelIconText labelIconTextDark" : "labelIconText" }>{this.props.labelText}</div>                                
                    </div>
                </a>                   
            </div>  
        )
    }
}


export default LabeledIconButton